<template>
  <!-- auth-page wrapper -->
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
      <div class="bg-overlay"></div>
      <!-- auth-page content -->
      <div class="auth-page-content overflow-hidden pt-lg-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="card overflow-hidden">
                <div class="row g-0">
                  <div class="col-lg-6">
                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                      <div class="bg-overlay"></div>
                    </div>
                  </div>
                  <!-- end col -->

                  <div class="col-lg-6">
                    <div class="p-lg-5 p-4">
                      <div>
                        <h5 class="text-primary">Welcome Back !</h5>
                        <p class="text-muted">Sign in to continue to Admin.</p>
                      </div>
      
                      <div class="mt-4">
                        <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
                        <div>
                        </div>
                        <form @submit.prevent="tryToLogIn">

                          <div class="mb-3">
                            <label for="username" class="form-label">Username</label>
                            <input v-model="username" type="text" class="form-control" id="username" placeholder="Enter username">
                          </div>

                          <div class="mb-3">
                            <label class="form-label" for="password-input">Password</label>
                            <div class="position-relative auth-pass-inputgroup mb-3">
                              <input v-model="password" type="password" class="form-control pe-5" placeholder="Enter password" id="password-input">
                              <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i class="ri-eye-fill align-middle"></i></button>
                            </div>
                          </div>
              
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="auth-remember-check">
                            <label class="form-check-label" for="auth-remember-check">Remember me</label>
                          </div>
                          
                          <div class="mt-4">
                            <button class="btn btn-info w-100" type="submit" @click="login">Sign In</button>
                          </div>
            
                          <div class="mt-4 text-center">
                            <div class="signin-other-title">
                              <h5 class="fs-13 mb-4 title">Sign In with</h5>
                            </div>
    
                            <div>
                              <button type="button" class="btn btn-icon waves-effect waves-light ms-1 m-1"><i class="provider-icon metamask fs-16"></i></button>
                              <button type="button" class="btn btn-icon waves-effect waves-light m-1"><i class="provider-icon walletconnect fs-16"></i></button>
                            </div>
                          </div>
        
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
                <!-- end row -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
  
          </div>
          <!-- end row -->
        </div>
        <!-- end container -->
      </div>
      <!-- end auth page content -->

      <!-- footer -->
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <p class="mb-0">&copy; {{new Date().getFullYear()}} Admin. Crafted with <i class="ri-heart-fill text-danger"></i> by JDMLabs</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>

<script>
  import {
    required,
    helpers
  } from '@vuelidate/validators';
  import appConfig from '@/../app.config';
  import axios from 'axios';
 
  export default {
    page: {
      title: 'Login',
      meta: [{
        name: 'description',
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        username: '',
        password: '',
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
      };
    },
    validations: {
      username: {
        required: helpers.withMessage('username is required', required),
      },
      password: {
        required: helpers.withMessage('Password is required', required),
      },
    },
    computed: {
    },
  methods: {
      async login() {
        const { data: { success, result } } = await axios.post(process.env.VUE_APP_API_URL + '/auth/login', {
          username: this.username,
          password: this.password
        })
        if (!success) {
          return this.authError = result;
      }
        localStorage.setItem('authToken', `${this.username}:${result.auth}`)
        this.$router.push({
          path: '/'
        });
      }
    },
  };
</script>